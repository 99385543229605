import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'core/services';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'core-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit {
  @Input() fileType: string = '.csv';
  @Input() placeholder: string = 'Enter value or drop your file here...';
  @Output() fileContentChange = new EventEmitter<string>();
  csvForm: FormGroup;
  private typingSubject: Subject<string> = new Subject<string>();
  @ViewChild('fileInputTextarea') fileInputTextarea!: ElementRef<HTMLTextAreaElement>;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Input() set initialValue(value: string) {
    this.csvForm.patchValue({ fileInput: value }, { emitEvent: false });
  }

  constructor(private fb: FormBuilder,
    private messageService: MessageService
  ) {
    this.csvForm = this.fb.group({
      fileInput: ['']
    });
  }

  ngOnInit(): void {
    this.typingSubject.pipe(debounceTime(1000)).subscribe(value => {
      this._emitFileContent(value);
    });
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (this.fileInputTextarea) {
      this.fileInputTextarea.nativeElement.classList.remove('no-drop');
    }
    if (files && files.length > 0) {
      this.onFileSelected(files[0]);
    }
  }

  onInputChange() {
    const value = this.csvForm.get('fileInput')?.value;
    this.typingSubject.next(value);
  }

  openFileDialog() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(file: File) {
    if (file) {
      if (file.name.endsWith(this.fileType)) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target?.result as string;
          this._emitFileContent(content);
        };
        reader.readAsText(file);
      } else {
        this.messageService.showWarning(["Only CSV file format is supported."], "File fomat not supported", false);
      }
      this.fileInput.nativeElement.value = "";
    }
  }

  private _emitFileContent(content: string) {
    this.fileContentChange.emit(content);
  }
}